import { useAuth } from 'react-oidc-context'
import React, { useEffect, useMemo } from 'react'
import TalkJs from './components/TalkJs'
import { Spinner } from './components/Spinner'
import { Header } from './components/Header'
import { ErrorPage } from './components/ErrorPage'

function App() {
  const auth = useAuth()
  const isLoggingOut = useMemo(() => window.location.href.includes('logout'), [
    window.location.href
  ])

  const signOutRedirect = () => {
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
    const logoutUri = process.env.REACT_APP_AUTH_LOGOUT_URI
    const cognitoDomain = process.env.REACT_APP_AUTH_DOMAIN
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`
  };

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated && !auth.error) {
      auth.signinRedirect()
    }

    if (isLoggingOut) {
      const logoutHandler = async () => {
        await auth.removeUser()
        await auth.clearStaleState()
        signOutRedirect()
      }
      logoutHandler()
    }
  }, [auth.isLoading, auth.isAuthenticated, auth.error, isLoggingOut])
  

  if (auth.isLoading) {
    return <Spinner />
  }

  if (auth.error) {
    return <ErrorPage error={auth.error.message} onClick={auth.signinRedirect} />
  }

  return (
    auth.isAuthenticated && (
      <>
        <Header onClick={signOutRedirect} />
        <div className="chat-container">
          <TalkJs 
            appId={String(process.env.REACT_APP_TALKJS_APP_ID)}
            userId={auth.user?.profile.talkjsUserId}
          />
        </div>
      </>
    )
  )
}
  
export default App
