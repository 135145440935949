import React from 'react'
import logo from '../assets/logo.svg'
import './header.css'

export const Header = ({onClick}) => (
  <header className="header">
    <div className="logo-container">
      <img src={logo} alt="logo" />
      <h4>Woofz: Chat Assistant</h4>
    </div>
    <button className="logout-button" onClick={onClick}>Sign out</button>
  </header>
)
