import { Inbox, Session } from '@talkjs/react'
import React from 'react'

 const TalkJs = ({appId, userId}) => (
  <div style={{ height: '500px' }}>
    <Session appId={appId} userId={userId}>
      <Inbox style={{ width: '100%', height: '500px' }} theme="cynologists_chat" />
    </Session>
  </div>
)

export default TalkJs
